


















































































































































































































































































import { Component, Provide, Vue } from "vue-property-decorator";
import pcDemo from '@/components/pcDemo.vue'
import { Picker, Search } from 'vant';
import Compressor from 'compressorjs';
import { CommonRes, CommonListRes, Page  } from "@/api/common";
import RegisterDialog from "@/components/RegisterSuccessDialog.vue";
import { Toast } from "vant";
import SendButton from "@/components/SendButton.vue";
import { Maintenance, MaintenanceReq, saveMaintaink, updateMaintain, uploadMaintainFiles, UploadRes } from "@/api/maintenance";
import RobotBoard from "@/components/RobotBoard.vue";
import { globalData } from "@/utils/config";
import { formatDate } from "@/utils/utils";
import { deleteFiles } from "@/api/file";
import {
  getRobotList,
  GetRobotListReq,
  getRobotStyleList,
  RobotStyle,
  Robot
} from "@/api/robot";
interface checkItem {
  title: string,
  isCleared: boolean,
  verified: boolean,
  fileList: any[],
  imgList: string[],
  phyImg: string[],
  content: string
}
@Component({
  components: {
    RegisterDialog,
    SendButton,
    RobotBoard,
    [Picker.name]: Picker,
    [Search.name]: Search,
    pcDemo
  },
})
export default class Index extends Vue {
  private show: boolean = false; 
  private robotName:string = '';
  private searchText:string = '';
  private showDateTime: boolean = false;
  private currentDate: Date = new Date();
  private maintain!: Maintenance;
  private isModify: boolean = false;
  private loading: boolean = false;
  private files: File[] = []
  private showSign: boolean = false;
  private signType: number = 0;
  private signImage:string = "";
  private clientSignImage:string ="";
  private picPref: string = globalData.exportFileUrl + 'maintain/';
  private timeType = 0;
  private mxlength = 1;
  private robotStyleIndex:number = 0;
  private robotIndex:number=0;
  private adminData = {
    admin: '',
    date: '',
    sign: ''
  }
  private clientData = {
    client: '',
    date: '',
    sign: ''
  }
  private checkData: checkItem[] = [
  {
      title: this.$t('inspect_1') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_2') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_3') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_4') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_5') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_6') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_7') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_8') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_9') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_10') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_11') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_12') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_13') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_14') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_15') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_16') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_17') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_18') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_19') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_20') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_21') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_22') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_23') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_24') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  },
  {
      title: this.$t('inspect_25') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
    }
  ]
  private req: MaintenanceReq = {
    companyName: "",
    robotId: "",
    machineModel: "",
    address:"",
    checkedBy: "",
    submitter: "",
    maintenanceTime: "",
    physicalInspect: "",
    inspectKeys: "",
    topCover: "",
    testButtons: "",
    inspectTablet: "",
    technicalPanel: "",
    checkForBattery: "",
    inspectWheel: "",
    inspectTankLeak: "",
    testSensors: "",
    inspectWaterTank: "",
    testBrush: "",
    inspectRobot: "",
    inspectQr: "",
    checkAutoLocation: "",
    testCharger: "",
    manualCleaning: "",
    autoCleaning: "",
    operateRobot: "",
    checkVirtualWall: "",
    checkAvoidance: "",
    testAutoDocking: "",
    testWaterPump: "",
    softwareUpgrade: "",
    refresher: "",
    adminInfo: "",
    clientInfo: ""
  };
  private email: string = "";
  private isSendSuccess: boolean = false;
  private machineModelName:string = "";
  private deleteImgList: string[] = []
  created() {
    console.log("register created");
    let maintaindetail = this.$route.params.maintaindetail;
    console.log('maintainList出口', maintaindetail)
    if (maintaindetail != undefined && maintaindetail != null && maintaindetail.length != 0) {
      this.maintain = JSON.parse(maintaindetail)
      this.req = this.maintain
      this.robotName = this.maintain.robotEntity.name
      this.isModify = true
      if(this.maintain.adminInfo != null) {
        this.adminData = JSON.parse(this.maintain.adminInfo)
        this.signImage = this.picPref + this.req.robotId + "/" + this.adminData.sign
      }
      if (this.maintain.clientInfo != null) {
        this.clientData = JSON.parse(this.maintain.clientInfo)
        this.clientSignImage = this.picPref  + this.req.robotId + "/" + this.clientData.sign
      }
      console.log(' this.signImage:' + this.signImage + ' this.clientSignImage：' + this.clientSignImage)
      this.setCheckData(0, this.maintain.physicalInspect)
      this.setCheckData(1, this.maintain.inspectKeys)
      this.setCheckData(2, this.maintain.topCover)
      this.setCheckData(3, this.maintain.testButtons)
      this.setCheckData(4, this.maintain.inspectTablet)
      this.setCheckData(5, this.maintain.technicalPanel)
      this.setCheckData(6, this.maintain.checkForBattery)
      this.setCheckData(7, this.maintain.inspectWheel)
      this.setCheckData(8, this.maintain.inspectTankLeak)
      this.setCheckData(9, this.maintain.testSensors)
      this.setCheckData(10, this.maintain.inspectWaterTank)
      this.setCheckData(11, this.maintain.testBrush)
      this.setCheckData(12, this.maintain.inspectRobot)
      this.setCheckData(13, this.maintain.inspectQr)
      this.setCheckData(14, this.maintain.checkAutoLocation)
      this.setCheckData(15, this.maintain.testCharger)
      this.setCheckData(16, this.maintain.manualCleaning)
      this.setCheckData(17, this.maintain.autoCleaning)
      this.setCheckData(18, this.maintain.autoCleaning)
      this.setCheckData(19, this.maintain.checkVirtualWall)
      this.setCheckData(20, this.maintain.checkAvoidance)
      this.setCheckData(21, this.maintain.testAutoDocking)
      this.setCheckData(22, this.maintain.testWaterPump)
      this.setCheckData(23, this.maintain.softwareUpgrade)
      this.setCheckData(24, this.maintain.refresher)
    }
    this.getRobotList()
   // this.getRobotStyleList()
    // if (window.history && window.history.pushState !== undefined) {
    //   // 向历史记录中插入了当前页
    //   history.pushState(null, '', document.URL);
    //   window.addEventListener('popstate', this.goBack, false);
    // }
  }
destroyed() { //离开这个界面之后，删除事件
  // window.removeEventListener('popstate', this.goBack, false);
}
private setCheckData (index: number, jsonStr: string) {
  let JsonObject = JSON.parse(jsonStr)
  this.checkData[index].verified = JsonObject.verified
  this.checkData[index].content = JsonObject.content
  if(JsonObject.content.length > 0 || JsonObject.img.length > 0) {
    this.checkData[index].isCleared = false
  }
  if(JsonObject.img != null && JsonObject.img.length > 0) {
    let phyImg:[] = JSON.parse(JsonObject.img)
    this.checkData[index].phyImg = phyImg
    phyImg.forEach(element => {
      this.checkData[index].imgList.push(this.picPref  + this.req.robotId + "/" + element)
    });
  }
 }
 private goBack() {
    console.log("触发返回");
    alert("触发返回")
    // 在这里写自己的业务逻辑
    // window.history.back();
    // this.modelVisible = true;
 }
 private clickStartTime(type: number) {
    this.showDateTime = true;
    this.timeType = type;
 }
 private showRemarkClick (item: any) {
  item.isCleared = !item.isCleared
 }
 private afterRead (fileInfo: any) {
    console.log('fileInfo', fileInfo)
    // this.files.push(fileInfo.file)
 }
 private onOversize() {
    Toast('文件大小不能超过 1000kb');
 }
 private deleteImg (item:checkItem, index:number) {
    this.deleteImgList.push( 'maintain/' + this.req.robotId + "/" + item.phyImg[index])
    item.imgList.splice(index, 1)
    item.phyImg.splice(index, 1)
 }
private fileMaxSize:number = 500 * 1024
 private  beforeRead (file: any) {
    let qualityk = this.fileMaxSize / file.size
    return new Promise((resolve, reject) => {
       new Compressor(file, { quality: qualityk,
            success(result) {
              console.log('result', result)
              resolve(result)
            },
            error(err) {
              console.log(err.message);
              reject(err)
            },
          });
    });
 }
 private confirmDateTime() {
    if (this.timeType == 0) {
      this.req.maintenanceTime = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    } else if (this.timeType == 1) {
      this.adminData.date = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    } else if (this.timeType == 2) {
      this.clientData.date = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    }
    this.showDateTime = false;
  }
  private clickSign (type:number) {
    if(this.isModify) {
      return
    }
    this.signType = type;
    this.showSign = true;
    console.log("showSign:" + this.showSign);
  }
  private clickCancleSign(type:number) {
      if (type == 0) {
         this.signImage = "";
      } else if (type == 1) {
        this.clientSignImage = "";
      }
  }
  private setSignPng (png:any) {
    if (this.signType == 0) {
      this.signImage = png;
    } else {
      this.clientSignImage = png;
    }
    console.log("signImage width:" + png.width + " height:" + png.height)
    this.showSign = false
  }
  private cancelDateTime() {
    this.showDateTime = false;
  }
  private async saveMaintainClick() {
    this.req.companyName = this.req.companyName.trim();
    if (this.req.companyName.length == 0) {
      Toast(this.$t('please_enter_the_company_name').toString());
      return;
    }
    this.req.robotId = this.req.robotId.trim();
    if (this.req.robotId.length == 0) {
      Toast(this.$t("Robot_no_empty").toString());
      return;
    }
    
    this.req.maintenanceTime = this.req.maintenanceTime.trim();
    if (this.req.maintenanceTime.length == 0) {
      Toast(this.$t("please_select_the_maintenance_time").toString());
      return;
    }
    // if (this.req.machineModel.trim().length == 0) {
    //   Toast(this.$t("please_select_machine_type").toString());
    //   return;
    // }
    this.req.checkedBy = this.req.checkedBy.trim();
    if (this.req.checkedBy.length == 0) {
      Toast(this.$t("please_fill_in_the_reviewer").toString());
      return;
    }
    if (this.req.address.length == 0) {
      Toast(this.$t("please_fill_in_the_address").toString());
      return;
    }
    this.adminData.admin = this.adminData.admin.trim()
    if (this.adminData.admin.length == 0) {
      Toast(this.$t("please_enter_administrator")).toString();
      return;
    }
    if (this.adminData.date.length == 0) {
      Toast(this.$t("please_select_date_for_admin")).toString();
      return;
    }
    this.clientData.client = this.clientData.client.trim()
    if (this.clientData.client.length == 0) {
      Toast(this.$t("please_enter_client")).toString();
      return;
    }
    if (this.clientData.date.length == 0) {
      Toast(this.$t("client_select_date")).toString();
      return;
    }
    console.log("s")
    let maintainDataList:any[] = []
    let recordFileIndex:any[] = []
    let filesEq: File[] = []
    for (let i = 0; i < this.checkData.length; i++) {
      let element = this.checkData[i]
      let maintainItem:any = {}
      maintainItem.verified = element.verified
      maintainItem.content = element.content
      if (element.fileList.length != 0) {
        recordFileIndex.push({index:i, length: element.fileList.length})
        element.fileList.forEach(element1 => {
          filesEq.push(element1.file)
        });
      }
      console.log("maintainItemAAAA length" + element.phyImg.length)
      if (element.phyImg.length !== 0) {
        console.log("maintainItemAAAA")
        maintainItem.img = JSON.stringify(element.phyImg)
      } else {
        console.log("maintainItemBBBB")
        maintainItem.img = ""
      }
      console.log("maintainItem", maintainItem)
      maintainDataList.push(maintainItem)
    }
    if (!this.isModify) {
      if (this.signImage.length > 0) {
        console.log("this.adminData.sign", this.signImage)
        filesEq.push(this.baseTofile(this.signImage, 'signImage.png'))
      } else {
        Toast(this.$t("admin_not_signed")).toString();
        return
      }
      if (this.clientSignImage.length > 0) {
        console.log("this.adminData.sign", this.clientSignImage)
        filesEq.push(this.baseTofile(this.clientSignImage, 'clientSignImage.png'))
      } else {
        Toast(this.$t("client_not_signed")).toString();
        return
      }
    }
    this.loading = true
    if (filesEq.length > 0) {
      console.log('filesEq', filesEq)
      let resFile: UploadRes = await uploadMaintainFiles(filesEq, this.req.robotId);
      if (resFile.code == 0) {
        console.log("resFile", resFile.files);
        let index = 0
        console.log("maintainDataListPOO：", maintainDataList)
        console.log("maintainDataListPOO recordFileIndex：", recordFileIndex)
        recordFileIndex.forEach(element => {
          console.log("maintainDataListItem：", maintainDataList[element.index])
          console.log("maintainDataListItem index：", element.index)
          let phyImg = this.checkData[element.index].phyImg
          let tempImg = resFile.files.slice(index, index + element.length)
          tempImg.forEach(element1 => {
            phyImg.push(element1)
          });
          maintainDataList[element.index].img = JSON.stringify(phyImg)
          index = index + element.length
        });
        if (!this.isModify) {
          this.adminData.sign = resFile.files[resFile.files.length - 2]
          this.clientData.sign = resFile.files[resFile.files.length - 1]
        }
      }
    }
    this.req.physicalInspect = JSON.stringify(maintainDataList[0]) 
    this.req.inspectKeys = JSON.stringify(maintainDataList[1]) 
    this.req.topCover = JSON.stringify(maintainDataList[2]) 
    this.req.testButtons = JSON.stringify(maintainDataList[3]) 
    this.req.inspectTablet = JSON.stringify(maintainDataList[4]) 
    this.req.technicalPanel = JSON.stringify(maintainDataList[5]) 
    this.req.checkForBattery = JSON.stringify(maintainDataList[6]) 
    this.req.inspectWheel =JSON.stringify(maintainDataList[7])
    this.req.inspectTankLeak = JSON.stringify(maintainDataList[8])
    this.req.testSensors = JSON.stringify(maintainDataList[9])
    this.req.inspectWaterTank = JSON.stringify(maintainDataList[10])
    this.req.testBrush = JSON.stringify(maintainDataList[11])
    this.req.inspectRobot = JSON.stringify(maintainDataList[12])
    this.req.inspectQr = JSON.stringify(maintainDataList[13])
    this.req.checkAutoLocation = JSON.stringify(maintainDataList[14])
    this.req.testCharger = JSON.stringify(maintainDataList[15])
    this.req.manualCleaning = JSON.stringify(maintainDataList[16])
    this.req.autoCleaning = JSON.stringify(maintainDataList[17])
    this.req.operateRobot = JSON.stringify(maintainDataList[18])
    this.req.checkVirtualWall = JSON.stringify(maintainDataList[19])
    this.req.checkAvoidance = JSON.stringify(maintainDataList[20])
    this.req.testAutoDocking = JSON.stringify(maintainDataList[21])
    this.req.testWaterPump = JSON.stringify(maintainDataList[22])
    this.req.softwareUpgrade = JSON.stringify(maintainDataList[23])
    this.req.refresher = JSON.stringify(maintainDataList[24])
    this.req.adminInfo = JSON.stringify(this.adminData)
    this.req.clientInfo = JSON.stringify(this.clientData)
    console.log("maintain", this.maintain)
    if (this.isModify) {
      let res1: CommonRes = await deleteFiles(this.deleteImgList);
      console.log('deleteRes:', res1)
      let res: CommonRes = await updateMaintain(this.maintain);
      console.log("res", res);
      if (res.code == 0) {
        Toast(this.$t("dealOK").toString())
        console.log(res.msg);
        this.$router.replace({
          path: "/fault",
        });
      }
      return
    } else {
      let res: CommonRes = await saveMaintaink(this.req);
      console.log("res", res);
      if (res.code == 0) {
        Toast(this.$t("dealOK").toString())
        console.log(res.msg);
        this.$router.replace({
          path: "/fault",
        });
      }
    }
    this.loading = false
    console.log("maintainDataList  this.req：",  this.req)
  }
  private robotList: Robot[] = [];
  private robotListAll: Robot[] = [];
  private checkSearchText(value: string) {
    console.log('checkSearchText', this.searchText)
    if (value != "") {
      this.robotList = this.filterList(this.robotListAll, this.searchText);
      this.columns = []
      let that = this;
      this.robotList.forEach(element => {
        that.columns.push(element.robotEntity.name + "(" + element.robotEntity.robotId + ")")
      });
    } else {
      this.getRobotList();
    }
  }
  private baseTofile(dataurl:any, fileName: string) {
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let  bstr = window.atob(arr[1]);
    let  n = bstr.length;
    let  u8arr = new Uint8Array(n);
    while (n--) {
       u8arr[n] = bstr.charCodeAt(n);
    }
    let dataURLtoBlob: any = new Blob([u8arr], { type: mime });
    // 将blob转换为file
    dataURLtoBlob.lastModifiedDate = new Date();
    dataURLtoBlob.name = fileName;
      // 调用
    return dataURLtoBlob;
  }
   private clearSearchText() {
    this.searchText = "";
    this.getRobotList();
  }
  private async getRobotList() {
    let req: GetRobotListReq = {
      page: 1,
      limit: 1000,
      // name: this.searchText,
    };
    let res: CommonListRes<Page<Robot>> = await getRobotList(req);
    this.robotList = []
    this.columns = []
    this.robotListAll = []
    if (res.code == 0) {
      this.robotListAll = this.robotListAll.concat(res.page.list);
      console.log("sss",this.robotListAll);
      if (this.robotName != "") {
        this.checkSearchText(this.robotName);
      } else {
        this.robotList = this.robotListAll;
      }
      let that = this;
      let index = 0
      this.robotList.forEach(element => {
        that.columns.push(element.robotEntity.name + "(" + element.robotEntity.robotId + ")")
        if (element.robotEntity.name == that.robotName) {
          that.robotIndex = index
        }
        index++
      });
    }
  }
  // private async getRobotStyleList() {
  //   let yjhType = localStorage.getItem('yjhType');
  //   if(yjhType == '-1') {
  //     let index = 0
  //     this.robotStyleList =  this.robotStyleList_YJH
  //     this.robotStyleList_YJH.forEach(element => {
  //         this.robotStyleColumns.push(element.robotStyle)
  //         if(element.id == this.req.machineModel) {
  //           this.machineModelName = element.robotStyle
  //           this.robotStyleIndex = index
  //         }
  //         index++
  //       });
  //   } else {
  //     let res: CommonListRes<Page<RobotStyle>> = await getRobotStyleList();
  //     this.robotStyleList = []
  //     this.robotStyleColumns = []
  //     if (res.code == 0) {
  //       this.robotStyleList = this.robotStyleList.concat(res.page.list);
  //       let that = this;
  //       let index = 0
  //       this.robotStyleList.forEach(element => {
  //         that.robotStyleColumns.push(element.robotStyle)
  //         if(element.id == this.req.machineModel) {
  //           that.machineModelName = element.robotStyle
  //           that.robotStyleIndex = index
  //         }
  //         index++
  //       });
  //     }
  //   }
  // }
  private filterList(list: Robot[], text: string) {
    return list.filter((item: Robot) => this.check(item, text));
  }
  private check(robot: Robot, text: string) {
    if (
      robot.robotEntity.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1 ||
      robot.robotEntity.robotId.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }
  private columns = ['1', '2'];
  private robotStyleColumns:string[] = [];
  private robotStyleList : RobotStyle[] = [];
  private robotStyleList_YJH : RobotStyle[] = [{id:-1, robotStyle: 'YJH'}];
  private showRobotStylePicker: boolean = false;
  private typeFlag!: number;
  private showPicker: boolean = false;
  private positionSearch: string = "";
  private changePicker() { //改变选择框内容
      this.showPicker = true
  }
  
 private onConfirm(value: string, index: number) { //选择框消失 赋值
    console.log("onConfirm:" + value)
    this.req.robotId = this.robotList[index].robotEntity.robotId
    this.robotName = value
    this.showPicker = false
    this.robotIndex = index
    if(this.robotList[index].robotEntity.robotType != undefined) {
      this.req.machineModel = this.robotList[index].robotEntity.robotType!
    } else {
      this.req.machineModel = "MX"
    }
 } 
 private showRtPicker() { //改变选择框内容
      this.showRobotStylePicker = true
 }
//   private onConfirmRt(value: string, index: number) { //选择框消失 赋值
//     console.log("onConfirm:" + value)
//     this.req.machineModel = this.robotStyleList[index].id
//     this.machineModelName = this.robotStyleList[index].robotStyle
//     this.showRobotStylePicker = false
//     this.robotStyleIndex = index
//  }
 private onCancelRt(value: any) { //选择框消失 赋值
    this.showRobotStylePicker = false
    console.log("onCancel:" + value)
 }
 private onCancel(value: any) { //选择框消失 赋值
    this.showPicker = false
    console.log("onCancel:" + value)
 }
 private onChange(value: any) { //选择框消失 赋值
    console.log("onChange:" + value)
    //this.showPicker = false
 }
  private back() {
    alert('哟乌恩替')
    return
   // this.$router.go(-1);
  }
}
