import request from '@/utils/request';
import { CommonRes } from './common';
/**
 * 删除故障
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
export function deleteFiles(names: string[]): Promise<CommonRes> {
    return request.delete<CommonRes>('/file/delete', names);
}