


















































































































































































import { Component, Provide, Vue } from "vue-property-decorator";
import pcDemo from '@/components/pcDemo.vue'
import { Picker, Search } from 'vant';
import { globalData } from "@/utils/config";
import Compressor from 'compressorjs';
import { CommonRes, CommonListRes, Page  } from "@/api/common";
import RegisterDialog from "@/components/RegisterSuccessDialog.vue";
import { Toast } from "vant";
import { uploadFiles, UploadRes } from "@/api/fault";
import SendButton from "@/components/SendButton.vue";
import { Maintenance, MaintenanceReq, saveMaintaink } from "@/api/maintenance";
import RobotBoard from "@/components/RobotBoard.vue";
import { formatDate, jsonParse } from "@/utils/utils";
import {
  getRobotList,
  GetRobotListReq,
  Robot
} from "@/api/robot";
interface checkItem {
  title: string,
  isCleared: boolean,
  verified: boolean,
  fileList: any[],
  content: string
}
@Component({
  components: {
    RegisterDialog,
    SendButton,
    RobotBoard,
    [Picker.name]: Picker,
    [Search.name]: Search,
    pcDemo
  },
})
export default class Index extends Vue {
  private show: boolean = false; 
  private robotName:string = '';
  private searchText:string = '';
  private currentDate: Date = new Date();
  private maintain!: Maintenance;
  private files: File[] = []
  private showSign: boolean = false;
  private signType: number = 0;
  private signImage:string = "";
  private clientSignImage:string ="";
  private timeType = 0;
  private picPref: string = globalData.exportFileUrl + 'maintain/';
  private images: string[] = []
  private showImg:boolean = false
  private showImgType: number = 0;
  private preBackground: string = "#000"
  private machineModelStr:string = "";
  private adminData = {
    admin: '',
    date: '',
    sign: ''
  }
  private clientData = {
    client: '',
    date: '',
    sign: ''
  }
  private checkData: checkItem[] = [
  {
      title: this.$t('inspect_1') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_2') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_3') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_4') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_5') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_6') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_7') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_8') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_9') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_10') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_11') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_12') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_13') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_14') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_15') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_16') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_17') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_18') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_19') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_20') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_21') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_22') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_23') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_24') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
  },
  {
      title: this.$t('inspect_25') + ':',
      isCleared: true,
      verified: false,
      fileList: [],
      content: ''
    }
  ]
  private req: MaintenanceReq = {
    companyName: "",
    robotId: "",
    machineModel: "",
    address:"",
    checkedBy: "",
    submitter: "",
    maintenanceTime: "",
    physicalInspect: "",
    inspectKeys: "",
    topCover: "",
    testButtons: "",
    inspectTablet: "",
    technicalPanel: "",
    checkForBattery: "",
    inspectWheel: "",
    inspectTankLeak: "",
    testSensors: "",
    inspectWaterTank: "",
    testBrush: "",
    inspectRobot: "",
    inspectQr: "",
    checkAutoLocation: "",
    testCharger: "",
    manualCleaning: "",
    autoCleaning: "",
    operateRobot: "",
    checkVirtualWall: "",
    checkAvoidance: "",
    testAutoDocking: "",
    testWaterPump: "",
    softwareUpgrade: "",
    refresher: "",
    adminInfo: "",
    clientInfo: ""
  };
  private indexImg: number = 0;
  created() {
    console.log("register created");
    let maintainDetail = this.$route.params.maintainDetail;
    console.log('faultdetail', maintainDetail)
    if (maintainDetail != undefined && maintainDetail != null && maintainDetail.length != 0) {
      // this.machineModelStr = this.$route.params.machineModelStr
      this.maintain = JSON.parse(maintainDetail)
      this.req = this.maintain
      this.robotName = this.maintain.robotEntity.name
      if(this.maintain.adminInfo != null) {
        this.adminData = JSON.parse(this.maintain.adminInfo)
        this.adminData.sign = this.picPref + this.maintain.robotId + "/" + this.adminData.sign
      }
      if (this.maintain.clientInfo != null) {
        this.clientData = JSON.parse(this.maintain.clientInfo)
        this.clientData.sign = this.picPref + this.maintain.robotId + "/" + this.clientData.sign
      }
      console.log('adminData', this.adminData.sign)
      // let physicalInspect = JSON.parse(this.maintain.physicalInspect)
      // this.checkData[0].verified = physicalInspect.verified
      // this.checkData[0].content = physicalInspect.content
      // if(physicalInspect.content.length > 0 || physicalInspect.img.length > 0) {
      //   this.checkData[0].isCleared = false
      // }
      // if(physicalInspect.img != null && physicalInspect.img.length > 0) {
      //   let phyImg:[] = JSON.parse(physicalInspect.img)
      //   phyImg.forEach(element => {
      //      this.checkData[0].fileList.push(this.picPref + element)
      //   });
      // }
      
      this.setCheckData(0, this.maintain.physicalInspect)
      this.setCheckData(1, this.maintain.inspectKeys)
      this.setCheckData(2, this.maintain.topCover)
      this.setCheckData(3, this.maintain.testButtons)
      this.setCheckData(4, this.maintain.inspectTablet)
      this.setCheckData(5, this.maintain.technicalPanel)
      this.setCheckData(6, this.maintain.checkForBattery)
      this.setCheckData(7, this.maintain.inspectWheel)
      this.setCheckData(8, this.maintain.inspectTankLeak)
      this.setCheckData(9, this.maintain.testSensors)
      this.setCheckData(10, this.maintain.inspectWaterTank)
      this.setCheckData(11, this.maintain.testBrush)
      this.setCheckData(12, this.maintain.inspectRobot)
      this.setCheckData(13, this.maintain.inspectQr)
      this.setCheckData(14, this.maintain.checkAutoLocation)
      this.setCheckData(15, this.maintain.testCharger)
      this.setCheckData(16, this.maintain.manualCleaning)
      this.setCheckData(17, this.maintain.autoCleaning)
      this.setCheckData(18, this.maintain.autoCleaning)
      this.setCheckData(19, this.maintain.checkVirtualWall)
      this.setCheckData(20, this.maintain.checkAvoidance)
      this.setCheckData(21, this.maintain.testAutoDocking)
      this.setCheckData(22, this.maintain.testWaterPump)
      this.setCheckData(23, this.maintain.softwareUpgrade)
      this.setCheckData(24, this.maintain.refresher)
    }
  }
  private setCheckData (index: number, jsonStr: string) {
    let JsonObject = JSON.parse(jsonStr)
    this.checkData[index].verified = JsonObject.verified
    this.checkData[index].content = JsonObject.content
    if(JsonObject.content.length > 0 || JsonObject.img.length > 0) {
      this.checkData[index].isCleared = false
    }
    if(JsonObject.img != null && JsonObject.img.length > 0) {
      let phyImg:[] = JSON.parse(JsonObject.img)
      console.log('phyImg', phyImg)
       console.log('phyImg length', phyImg.length)
      phyImg.forEach(element => {
         console.log('phyImg element', element)
        this.checkData[index].fileList.push(this.picPref + this.maintain.robotId + "/" + element)
      });
      console.log('phyImg checkData',  this.checkData[index].fileList)
    }
 }
 private onOversize() {
    Toast('文件大小不能超过 1000kb');
 }
 private imageClick (fileList:string[], showImgType: number) {
    this.showImg = true
    this.showImgType = showImgType
    this.images = fileList
 }
  private robotList: Robot[] = [];
  private robotListAll: Robot[] = [];
  private checkSearchText(value: string) {
    console.log('checkSearchText', this.searchText)
    if (value != "") {
      this.robotList = this.filterList(this.robotListAll, this.searchText);
      this.columns = []
      let that = this;
      this.robotList.forEach(element => {
        that.columns.push(element.robotEntity.name + "(" + element.robotEntity.robotId + ")")
      });
    } else {
      this.getRobotList();
    }
  }
  private baseTofile(dataurl:any, fileName: string) {
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let  bstr = window.atob(arr[1]);
    let  n = bstr.length;
    let  u8arr = new Uint8Array(n);
    while (n--) {
       u8arr[n] = bstr.charCodeAt(n);
    }
    let dataURLtoBlob: any = new Blob([u8arr], { type: mime });
    // 将blob转换为file
    dataURLtoBlob.lastModifiedDate = new Date();
    dataURLtoBlob.name = fileName;
      // 调用
    return dataURLtoBlob;
  }
   private clearSearchText() {
    this.searchText = "";
    this.getRobotList();
  }
  private async getRobotList() {
    let req: GetRobotListReq = {
      page: 1,
      limit: 1000,
      // name: this.searchText,
    };
    let res: CommonListRes<Page<Robot>> = await getRobotList(req);
    this.robotList = []
    this.columns = []
    this.robotListAll = []
    if (res.code == 0) {
      this.robotListAll = this.robotListAll.concat(res.page.list);
      console.log("sss",this.robotListAll);
      if (this.robotName != "") {
        this.checkSearchText(this.robotName);
      } else {
        this.robotList = this.robotListAll;
      }
      let that = this;
      this.robotList.forEach(element => {
        that.columns.push(element.robotEntity.name + "(" + element.robotEntity.robotId + ")")
      });
    }
  }
  private filterList(list: Robot[], text: string) {
    return list.filter((item: Robot) => this.check(item, text));
  }
  private check(robot: Robot, text: string) {
    if (
      robot.robotEntity.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1 ||
      robot.robotEntity.robotId.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }
  private columns = ['1', '2'];
  private typeFlag!: number;
  private positionSearch: string = "";
 private onChangeImg (newIndex: any) {
    this.indexImg = newIndex;
  }
  private back() {
    this.$router.go(-1);
  }
}
