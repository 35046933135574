






































































































































































































































import { Component, Provide, Vue } from "vue-property-decorator";
import pcDemo from '@/components/pcDemo.vue'
import { Picker, Search } from 'vant';
import Compressor from 'compressorjs';
import { CommonRes, CommonListRes, Page  } from "@/api/common";
import RegisterDialog from "@/components/RegisterSuccessDialog.vue";
import { Toast } from "vant";
import SendButton from "@/components/SendButton.vue";
import { Repair, RepairReq, saveRepairk, updateRepair, uploadRepairFiles, UploadRes } from "@/api/repair";
import RobotBoard from "@/components/RobotBoard.vue";
import { formatDate } from "@/utils/utils";
import { globalData } from "@/utils/config";
import { deleteFiles } from "@/api/file";
import {
  getRobotList,
  GetRobotListReq,
  Robot,
  getRobotStyleList,
  RobotStyle,
} from "@/api/robot";
interface checkItem {
  title: string,
  fileList: any[],
  content: string,
  imgList: string[],
  phyImg: string[]
}
@Component({
  components: {
    RegisterDialog,
    SendButton,
    RobotBoard,
    [Picker.name]: Picker,
    [Search.name]: Search,
    pcDemo
  },
})
export default class Index extends Vue {
  private robotName:string = '';
  private searchText:string = '';
  private showDateTime: boolean = false;
  private currentDate: Date = new Date();
  private repair!: Repair;
  private isModify: boolean = false;
  private loading: boolean = false;
  private robotStyleList_YJH : RobotStyle[] = [{id:-1, robotStyle: 'YJH'}];
  private showSign: boolean = false;
  private signImage:string = "";
  private robotStyleColumns:string[] = [];
  private robotStyleList : RobotStyle[] = [];
  private showRobotStylePicker: boolean = false;
  private timeType = 0;
  private machineModelName:string = "";
  private robotStyleIndex:number = 0;
  private robotIndex:number=0;
  private picPref: string = globalData.exportFileUrl + "repair/";
  private deleteImgList: string[] = []
  private adminData = {
    admin: '',
    date: '',
    sign: ''
  }
  private checkData: checkItem[] = []
  private req: RepairReq = {
    companyName: "",
    robotId: "",
    machineModel: "",
    address:"",
    checkedBy: "",
    submitter: "",
    repairTime: "",
    entryList: "",
    adminInfo: ""
  };
  private email: string = "";
  private isSendSuccess: boolean = false;
  created() {
    console.log("register created");
    console.log("checkData初", this.checkData)
    let repairDetail = this.$route.params.repairDetail;
    console.log('repairDetail', repairDetail)
    if (repairDetail != undefined && repairDetail != null && repairDetail.length != 0) {
      this.repair = JSON.parse(repairDetail)
      this.req = this.repair
      this.robotName = this.repair.robotEntity.name
      this.isModify = true
      if(this.repair.adminInfo != null) {
        this.adminData = JSON.parse(this.repair.adminInfo)
        this.signImage = this.picPref + this.req.robotId + "/" + this.adminData.sign
      }
      let entryList:any[] = JSON.parse(this.req.entryList)
      let index = 1
      entryList.forEach(element1 => {
        let checkItem:checkItem =  {
          title: this.$t('question') + '1:',
          fileList: [],
          content: '',
          phyImg: [],
          imgList:[]
        }
        checkItem.title = this.$t('question').toString() + index + ':',
        checkItem.content = element1.content
        if (element1.img != undefined && element1.img.length > 0) {
          let imgJson:string[] = JSON.parse(element1.img)
          checkItem.phyImg = imgJson
          imgJson.forEach(element2 => {
            checkItem.imgList.push( this.picPref + this.req.robotId + "/" + element2)
          });
        }
        this.checkData.push(checkItem)
        index++
      });
    }
    this.getRobotList()
    // this.getRobotStyleList()
    if (!this.isModify) {
      this.newEntry()
    }
  }
 private clickStartTime(type: number) {
    this.showDateTime = true;
    this.timeType = type;
 }
 private showRemarkClick (item: any) {
  item.isCleared = !item.isCleared
 }
 private deleteImg (item:checkItem, index:number) {
   this.deleteImgList.push( 'repair/' + this.req.robotId + "/" + item.phyImg[index])
   item.imgList.splice(index, 1)
   item.phyImg.splice(index, 1)
 }
 private afterRead (fileInfo: any) {
    console.log('fileInfo', fileInfo)
    // this.files.push(fileInfo.file)
 }
 private onOversize() {
    Toast('文件大小不能超过 1000kb');
 }
 
 private showRtPicker() { //改变选择框内容
      this.showRobotStylePicker = true
 }
//   private onConfirmRt(value: string, index: number) { //选择框消失 赋值
//     console.log("onConfirm:" + value + "index:" + index)
//     this.req.machineModel = this.robotStyleList[index].id
//     this.machineModelName = this.robotStyleList[index].robotStyle
//     this.showRobotStylePicker = false
//     this.robotStyleIndex = index
//      console.log("onConfirm:" + value + "index:" + index + " robotStyleIndex:" + this.robotStyleIndex)
//  }
 private onCancelRt(value: any) { //选择框消失 赋值
    this.showRobotStylePicker = false
    console.log("onCancel:" + value)
 }
//  private async getRobotStyleList() {
//     let yjhType = localStorage.getItem('yjhType');
//     if(yjhType == '-1') {
//       let index = 0
//       this.robotStyleList =  this.robotStyleList_YJH
//       this.robotStyleList_YJH.forEach(element => {
//           this.robotStyleColumns.push(element.robotStyle)
//           if(element.id == this.req.machineModel) {
//             this.machineModelName = element.robotStyle
//             this.robotStyleIndex = index
//           }
//           index++
//         });
//     } else {
//       let res: CommonListRes<Page<RobotStyle>> = await getRobotStyleList();
//       this.robotStyleList = []
//       this.robotStyleColumns = []
//       if (res.code == 0) {
//         this.robotStyleList = this.robotStyleList.concat(res.page.list);
//         let that = this;
//         let index = 0
//         this.robotStyleList.forEach(element => {
//           that.robotStyleColumns.push(element.robotStyle)
//           if(element.id == this.req.machineModel) {
//             that.machineModelName = element.robotStyle
//             that.robotStyleIndex = index
//           }
//           index++
//         });
//       }
//     }
//   }
//  private async getRobotStyleList() {
//     let res: CommonListRes<Page<RobotStyle>> = await getRobotStyleList();
//     this.robotStyleList = []
//     this.robotStyleColumns = []
//     if (res.code == 0) {
//       this.robotStyleList = this.robotStyleList.concat(res.page.list);
//       let that = this;
//       let index = 0
//       this.robotStyleList.forEach(element => {
//         that.robotStyleColumns.push(element.robotStyle)
//         if(element.id == this.req.machineModel) {
//           that.machineModelName = element.robotStyle
//           that.robotStyleIndex = index
//         }
//         index++
//       });
//     }
//   }
private fileMaxSize:number = 500 * 1024
 private  beforeRead (file: any) {
    let qualityk = this.fileMaxSize / file.size
    return new Promise((resolve, reject) => {
       new Compressor(file, { quality: qualityk,
            success(result) {
              console.log('result', result)
              resolve(result)
            },
            error(err) {
              console.log(err.message);
              reject(err)
            },
          });
    });
 }
 private confirmDateTime() {
    if (this.timeType == 0) {
      this.req.repairTime = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    } else if (this.timeType == 1) {
      this.adminData.date = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    } 
    this.showDateTime = false;
  }
  private clickSign () {
    if(this.isModify) {
      return
    }
    this.showSign = true;
    console.log("showSign:" + this.showSign);
  }
  private clickCancleSign() {
       this.signImage = "";
  }
  private setSignPng (png:any) {
    this.signImage = png;
    this.showSign = false
  }
  private cancelDateTime() {
    this.showDateTime = false;
  }
 private async saveRepairClick() {
    this.req.companyName = this.req.companyName.trim();
    if (this.req.companyName.length == 0) {
      Toast(this.$t('please_enter_the_company_name').toString());
      return;
    }
    this.req.robotId = this.req.robotId.trim();
    if (this.req.robotId.length == 0) {
      Toast(this.$t("Robot_no_empty").toString());
      return;
    }
    this.req.repairTime = this.req.repairTime.trim();
    if (this.req.repairTime.length == 0) {
      Toast(this.$t("please_select_the_maintenance_time").toString());
      return;
    }
    // if (this.machineModelName.trim().length == 0) {
    //   Toast(this.$t("please_select_machine_type").toString());
    //   return;
    // }
    this.req.checkedBy = this.req.checkedBy.trim();
    if (this.req.checkedBy.length == 0) {
      Toast(this.$t("please_fill_in_the_reviewer").toString());
      return;
    }
    if (this.req.address.length == 0) {
      Toast(this.$t("please_fill_in_the_address").toString());
      return;
    }
    if(this.checkData ==undefined || this.checkData[0].content.length == 0 ) {
      Toast(this.$t("fault_content")).toString();
      return;
    }
    this.adminData.admin = this.adminData.admin.trim()
    if (this.adminData.admin.length == 0) {
      Toast(this.$t("please_enter_administrator")).toString();
      return;
    }
    if (this.adminData.date.length == 0) {
      Toast(this.$t("please_select_date_for_admin")).toString();
      return;
    }
    console.log("s")
    let repairDataList:any[] = []
    let recordFileIndex:any[] = []
    let filesEq: File[] = []
    for (let i = 0; i < this.checkData.length; i++) {
      let element = this.checkData[i]
      let repairItem:any = {}
      repairItem.content = element.content
      if (element.fileList.length != 0) {
        recordFileIndex.push({index:i, length: element.fileList.length})
        element.fileList.forEach(element1 => {
          filesEq.push(element1.file)
        });
      }
       if (element.phyImg.length !== 0) {
        repairItem.img = JSON.stringify(element.phyImg)
      } else {
        repairItem.img = ""
      }
      repairDataList.push(repairItem)
    }
    if (!this.isModify) {
      if(this.signImage.length > 0) {
        console.log("this.adminData.sign", this.signImage)
        filesEq.push(this.baseTofile(this.signImage, 'signImage.png'))
      } else {
        Toast(this.$t("admin_not_signed")).toString();
        return
      }
    }
    this.loading = true
    if (filesEq.length > 0) {
      let resFile: UploadRes = await uploadRepairFiles(filesEq, this.req.robotId);
      console.log("adminData resFile", resFile);
      if (resFile.code == 0) {
          let index = 0
          console.log("repairDataListPOO：", repairDataList)
          console.log("repairDataListPOO recordFileIndex：", recordFileIndex)
          recordFileIndex.forEach(element => {
            console.log("repairDataListItem：", repairDataList[element.index])
            console.log("repairDataListItem index：", element.index)
            // repairDataList[element.index].img = JSON.stringify(resFile.files.slice(index, index + element.length))
            let phyImg = this.checkData[element.index].phyImg
            let tempImg = resFile.files.slice(index, index + element.length)
            tempImg.forEach(element1 => {
              phyImg.push(element1)
            });
            repairDataList[element.index].img = JSON.stringify(phyImg)
            index = index + element.length
          });
          if (!this.isModify) {
            this.adminData.sign = resFile.files[resFile.files.length - 1]
            console.log("this.adminData", this.adminData)
          }
      } else{
        alert("图片上传失败！")
        return
      }
    }
    this.req.entryList = JSON.stringify(repairDataList)
    this.req.adminInfo = JSON.stringify(this.adminData)
    console.log("this.adminData", this.req)
    if (this.isModify) {
      let res1: CommonRes = await deleteFiles(this.deleteImgList);
      console.log('deleteRes:', res1)
      let res: CommonRes = await updateRepair(this.repair);
      console.log("res", res);
      if (res.code == 0) {
        Toast(this.$t("dealOK").toString())
        console.log(res.msg);
        this.$router.replace({
          path: "/fault",
        });
      }
      return
    } else {
      let res: CommonRes = await saveRepairk(this.req);
      console.log("res", res);
      if (res.code == 0) {
        Toast(this.$t("dealOK").toString())
        console.log(res.msg);
        this.$router.replace({
          path: "/fault",
        });
      }
    }
    // let res: CommonRes = await saveMaintain(this.req);
    // console.log("res", res);
    // if (res.code == 0) {
    //   Toast(this.$t("dealOK").toString())
    //   this.$router.replace({
    //     path: "/fault",
    //   });
    // } else {
    //   console.log(res.msg);
    // }
    this.loading = false
    console.log("repairDataList  this.req：",  this.req)
  }
  private robotList: Robot[] = [];
  private robotListAll: Robot[] = [];
  private checkSearchText(value: string) {
    console.log('checkSearchText', this.searchText)
    if (value != "") {
      this.robotList = this.filterList(this.robotListAll, this.searchText);
      this.columns = []
      let that = this;
      this.robotList.forEach(element => {
        that.columns.push(element.robotEntity.name + "(" + element.robotEntity.robotId + ")")
      });
    } else {
      this.getRobotList();
    }
  }
  private baseTofile(dataurl:any, fileName: string) {
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let  bstr = window.atob(arr[1]);
    let  n = bstr.length;
    let  u8arr = new Uint8Array(n);
    while (n--) {
       u8arr[n] = bstr.charCodeAt(n);
    }
    let dataURLtoBlob: any = new Blob([u8arr], { type: mime });
    // 将blob转换为file
    dataURLtoBlob.lastModifiedDate = new Date();
    dataURLtoBlob.name = fileName;
      // 调用
    return dataURLtoBlob;
  }
   private clearSearchText() {
    this.searchText = "";
    this.getRobotList();
  }
  private async getRobotList() {
    let req: GetRobotListReq = {
      page: 1,
      limit: 1000,
      // name: this.searchText,
    };
    let res: CommonListRes<Page<Robot>> = await getRobotList(req);
    this.robotList = []
    this.columns = []
    this.robotListAll = []
    if (res.code == 0) {
      this.robotListAll = this.robotListAll.concat(res.page.list);
      console.log("sss",this.robotListAll);
      if (this.robotName != "") {
        this.checkSearchText(this.robotName);
      } else {
        this.robotList = this.robotListAll;
      }
      let that = this;
      let index = 0
      this.robotList.forEach(element => {
        that.columns.push(element.robotEntity.name + "(" + element.robotEntity.robotId + ")")
        if (element.robotEntity.name == that.robotName) {
          that.robotIndex = index
        }
        index++
      });
    }
  }
  private filterList(list: Robot[], text: string) {
    return list.filter((item: Robot) => this.check(item, text));
  }
  private check(robot: Robot, text: string) {
    if (
      robot.robotEntity.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1 ||
      robot.robotEntity.robotId.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }
  private columns = ['1', '2'];
  private typeFlag!: number;
  private showPicker: boolean = false;
  private positionSearch: string = "";
  private changePicker() { //改变选择框内容
      this.showPicker = true
  }
 private onConfirm(value: string, index: number) { //选择框消失 赋值
    console.log("onConfirm:" + value)
    this.req.robotId = this.robotList[index].robotEntity.robotId
    if(this.robotList[index].robotEntity.robotType != undefined) {
      this.req.machineModel = this.robotList[index].robotEntity.robotType!
    } else {
      this.req.machineModel = "MX"
    }
    this.robotName = value
    this.showPicker = false
    this.robotIndex = index
 }
 private newEntry () {
  let checkIndex:number = this.checkData.length + 1
  let checkString = checkIndex + ":"
  let item: checkItem =  {
      title: this.$t('question') + checkString,
      fileList: [],
      content: '',
      imgList: [],
      phyImg: []
  }
  this.checkData.push (item)
 }
 private onCancel(value: any) { //选择框消失 赋值
    this.showPicker = false
    console.log("onCancel:" + value)
 }
 private onChange(value: any) { //选择框消失 赋值
    console.log("onChange:" + value)
    //this.showPicker = false
 }
  private back() {
    this.$router.go(-1);
  }
}
