





































































































































































































import { Component, Provide, Vue } from "vue-property-decorator";
import { Picker, Search } from 'vant';
import Compressor from 'compressorjs';
import {
  sendSMS,
  SendSMSReq,
  login,
  LoginReq,
  LoginRes,
  register,
  RegisterReq,
} from "@/api/user";
import { CommonRes, CommonListRes, Page  } from "@/api/common";
import RegisterDialog from "@/components/RegisterSuccessDialog.vue";
import { Toast } from "vant";
import { checkEmail, checkPassword } from "@/utils/utils";
import SendButton from "@/components/SendButton.vue";
import { saveFault, updateFault, FaultReq, Fault, uploadRequestFiles, Uploadeq, UploadRes, UploadFileRes } from "@/api/fault";
import RobotBoard from "@/components/RobotBoard.vue";
import { formatDate } from "@/utils/utils";
import { globalData } from "@/utils/config";
import { deleteFiles } from "@/api/file";
import TipDialog from "@/components/TipDialog.vue";
import router from '../../router'
import {
  getRobotList,
  GetRobotListReq,
  Robot,
  unbindRobot,
  UnBindRobotReq,
  updateRobot,
  UpdateRobotReq,
} from "@/api/robot";
@Component({
  components: {
    RegisterDialog,
    SendButton,
    RobotBoard,
    [Picker.name]: Picker,
    [Search.name]: Search,
    TipDialog
  },
})
export default class Index extends Vue {
  private show: boolean = false; 
  private showDialog: boolean = false;
  private robotName:string = '';
  private showToolBar:boolean=true;
  private searchText:string = '';
  private showDateTime: boolean = false;
  private currentDate: Date = new Date();
  private fault!: Fault;
  private isModify: boolean = false;
  private loading: boolean = false;
  private files: File[] = []
  private picPref: string = globalData.exportFileUrl + "request/";
  private imgList: string[] = []
  private phyImgList: string[] = []
  private deleteImgList: string[] = []
  private req: FaultReq = {
    faultType: -1,
    robotId: "",
    state: 0,
    title: "",
    noteTaker: "",
    content: "",
    recordTime: "",
    faultTime: "",
    file: "",
    remark: "",
    contactInfo: "",
    address: ""
  };
  private fileList : any[] = [];
  private email: string = "";
  private isSendSuccess: boolean = false;
  created() {
    console.log("register created");
    let faultdetail = this.$route.params.faultdetail;
    console.log('faultdetail', faultdetail)
    if (faultdetail != undefined && faultdetail != null && faultdetail.length != 0) {
      this.fault = JSON.parse(faultdetail)
      this.req = this.fault
      this.robotName = this.fault.robotEntity.name
      this.isModify = true
      let imgJson:string[] = JSON.parse(this.fault.file)
      this.phyImgList = imgJson
      imgJson.forEach(element2 => {
        this.imgList.push( this.picPref + this.req.robotId + "/" + element2 )
      });
    }
    this.getRobotList()
    // let that = this
    // router.beforeResolve((to, from, next) => {
    //   that.showDialog = true;
    //   // next();
    // });
  }
  private confirmEvent(){
    this.show = false;
  }
  private cancelEvent(){
     this.show = false;
  }
 private clickStartTime() {
    this.showDateTime = true;
 }
 private afterRead (fileInfo: any) {
    console.log('fileInfo', fileInfo)
    // this.files.push(fileInfo.file)
 }
 private onOversize() {
    Toast('文件大小不能超过 1000kb');
 }
  private deleteImg (index:number) {
    this.deleteImgList.push( 'request/' + this.req.robotId + "/" + this.phyImgList[index])
    this.imgList.splice(index, 1)
    this.phyImgList.splice(index, 1)
 }
private fileMaxSize:number = 500 * 1024
 private  beforeRead (file: any) {
    let qualityk = this.fileMaxSize / file.size
    return new Promise((resolve, reject) => {
       new Compressor(file, { quality: qualityk,
            success(result) {
              console.log('result', result)
              resolve(result)
            },
            error(err) {
              console.log(err.message);
              reject(err)
            },
          });
    });
 }
 private confirmDateTime() {
    this.showDateTime = false;
    this.req.faultTime = formatDate(this.currentDate.getTime() / 1000, "-", ":");
  }

  private cancelDateTime() {
    this.showDateTime = false;
  }
 private async saveFault() {
  this.req.title = this.req.title.trim();
  if (this.req.title.length == 0) {
    Toast(this.$t("Fault_no_empty").toString());
    return;
  }
  if (this.req.faultType === -1) {
    Toast(this.$t("select_fault_type").toString());
    return;
  }
  this.req.robotId = this.req.robotId.trim();
  if (this.req.robotId.length == 0) {
    Toast(this.$t("Robot_no_empty").toString());
    return;
  }
  if (this.req.faultTime.length == 0) {
    Toast(this.$t("Fault_time_no_empty").toString());
    return;
  }
  if (this.req.contactInfo.length == 0) {
    Toast(this.$t("fault_contact").toString());
    return;
  }
  this.req.content = this.req.content.trim();
  if (this.req.content.length == 0) {
    Toast(this.$t("Fault_details_no_empty").toString());
    return;
  }
  // Toast("this.isModify:" + this.isModify)
  console.log("this.isModify:" + this.isModify)
  let filesEq: File[] = []
  this.fileList.forEach(element => {
    filesEq.push(element.file)
  });
  if (this.isModify) {
    // let res: CommonRes = await updateFault(this.fault);
    // console.log("res", res);
    // if (res.code == 0) {
    //   Toast(this.$t("dealOK").toString())
    //   console.log(res.msg);
    //   this.$router.replace({
    //     path: "/fault",
    //   });
    // }
    let res1: CommonRes = await deleteFiles(this.deleteImgList);
    console.log('deleteRes:', res1)
    if (filesEq != undefined && filesEq.length > 0) {
      let resFile: UploadRes = await uploadRequestFiles(filesEq, this.req.robotId);
      console.log("resFile", resFile);
      console.log("fileList", this.fileList);
      if (resFile.code == 0) {
          this.req.noteTaker = (String)(localStorage.getItem("account"));
          resFile.files.forEach(element => {
            this.phyImgList.push(element)
          });
          this.req.file = JSON.stringify(this.phyImgList)
          console.log("this.req", this.req);
          let res: CommonRes = await updateFault(this.fault);
          console.log("res", res);
          if (res.code == 0) {
            Toast(this.$t("dealOK").toString())
            this.$router.replace({
              path: "/fault",
            });
          } else {
            console.log(res.msg);
          }
          this.loading = false
        } else {
          console.log(resFile);
          Toast(this.$t("save_file_failed").toString())
          this.loading = false
        }
    } else {
      this.req.noteTaker = (String)(localStorage.getItem("account"));
      this.req.file = JSON.stringify(this.phyImgList)
      console.log("this.req", this.req);
      let res: CommonRes = await updateFault(this.fault);
      console.log("res", res);
      if (res.code == 0) {
        Toast(this.$t("dealOK").toString())
          this.$router.replace({
            path: "/fault",
          });
        } else {
          console.log(res.msg);
      }
        this.loading = false
    }
    return
  }
  this.loading = true;
  // 上传图片、视频文件
  if (filesEq != undefined && filesEq.length > 0) {
    let resFile: UploadRes = await uploadRequestFiles(filesEq, this.req.robotId);
    console.log("resFile", resFile);
    console.log("fileList", this.fileList);
    if (resFile.code == 0) {
      this.req.noteTaker = (String)(localStorage.getItem("account"));
      this.req.file = JSON.stringify(resFile.files)
      console.log("this.req", this.req);
      let res: CommonRes = await saveFault(this.req);
      console.log("res", res);
      if (res.code == 0) {
        Toast(this.$t("dealOK").toString())
        this.$router.replace({
          path: "/fault",
        });
      } else {
        console.log(res.msg);
      }
      this.loading = false
    } else {
      console.log(resFile);
      Toast(this.$t("save_file_failed").toString())
      this.loading = false
    }
  } else {
    this.req.noteTaker = (String)(localStorage.getItem("account"));
      console.log("this.req", this.req);
      let res: CommonRes = await saveFault(this.req);
      console.log("res", res);
      if (res.code == 0) {
        Toast(this.$t("dealOK").toString())
        this.$router.replace({
          path: "/fault",
        });
      } else {
        console.log(res.msg);
      }
      this.loading = false
  }
}
  private robotList: Robot[] = [];
  private robotListAll: Robot[] = [];
  private checkSearchText(value: string) {
    console.log('checkSearchText', this.searchText)
    if (value != "") {
      this.robotList = this.filterList(this.robotListAll, this.searchText);
      this.columns = []
      let that = this;
      this.robotList.forEach(element => {
        that.columns.push(element.robotEntity.name + "(" + element.robotEntity.robotId + ")")
      });
    } else {
      this.getRobotList();
    }
  }
  private clearSearchText() {
    this.searchText = "";
    this.getRobotList();
  }
  private async getRobotList() {
    let req: GetRobotListReq = {
      page: 1,
      limit: 1000,
      // name: this.searchText,
    };
    let res: CommonListRes<Page<Robot>> = await getRobotList(req);
    this.robotList = []
    this.columns = []
    this.robotListAll = []
    if (res.code == 0) {
      this.robotListAll = this.robotListAll.concat(res.page.list);
      console.log("sss",this.robotListAll);
      if (this.robotName != "") {
        this.checkSearchText(this.robotName);
      } else {
        this.robotList = this.robotListAll;
      }
      let that = this;
      this.robotList.forEach(element => {
        that.columns.push(element.robotEntity.name + "(" + element.robotEntity.robotId + ")")
      });
    }
  }
  private filterList(list: Robot[], text: string) {
    return list.filter((item: Robot) => this.check(item, text));
  }
  private check(robot: Robot, text: string) {
    if (
      robot.robotEntity.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1 ||
      robot.robotEntity.robotId.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }
  private columns = ['1', '2'];
  private typeFlag!: number;
  private showPicker: boolean = false;
  private positionSearch: string = "";
  private changePicker() { //改变选择框内容
      this.showPicker = true
  }
 private onConfirm(value: string, index: number) { //选择框消失 赋值
    console.log("onConfirm:" + value)
    this.req.robotId = this.robotList[index].robotEntity.robotId
    this.robotName = value
    this.showPicker = false
 }
 private onCancel(value: any) { //选择框消失 赋值
    this.showPicker = false
    console.log("onCancel:" + value)
 }
 private onChange(value: any) { //选择框消失 赋值
    console.log("onChange:" + value)
    //this.showPicker = false
 }
  private back() {
    this.$router.go(-1);
  }
  private radioVal: string = "";
  private getRadioVal(faultType:number) { 
   console.log(this.radioVal);
   this.req.faultType = faultType
  }

  private backToLoginEvent() {
    this.show = false;
    this.$router.replace({
      path: "/login",
    });
  }
}
